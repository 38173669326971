import React, { Component } from 'react';
import './MainView.scss';
import RouletteView from '../RouletteView';
import SpinningView from '../SpinningView';

// import PeopleSelector from '../PeopleSelector';

export class MainView extends Component {
	data = []
	
	constructor() {
		super();
		this.state = { "view": "selection", "endgame": false, "audiolength": 0, "fadingDone": false };
		this.switchToSpinView = this.switchToSpinView.bind(this);
		this.playAudio = this.playAudio.bind(this)
	}

	playAudio() {
		const audioEl = document.getElementById("audio");
		console.log(audioEl)
		audioEl.loop = false;
		this.setState({audiolength: Math.floor( audioEl.duration )})
		audioEl.play();
	}
	
	switchToSpinView(e) {
		if( e.keyCode === 32 ) {
			if(this.state.endgame && this.state.fadingDone) {
				console.log("start spin");
				this.playAudio();
				let checkboxes = document.getElementsByClassName("checkbox-element");
				let data = []

				for (var i = 0; i < checkboxes.length; i++) {
					if( checkboxes[i].classList.contains("checked") ){
						data.push({ "id":checkboxes[i].dataset.id, "name":checkboxes[i].dataset.name, "role": checkboxes[i].dataset.role })
					}
				}
				
				this.data = data;
				document.removeEventListener("keydown", this.switchToSpinView, false);
				this.setState({"view": "spinner"});
			} else {
				this.setState({endgame: true});
				setTimeout(() => {
					this.setState({fadingDone: true})
				}, 3500)
			}
		}
	}

	componentDidMount(){
		document.addEventListener("keydown", this.switchToSpinView, false);
	}

	render() {
		return (
			<>
			<div id="main-bg" className={ this.state.endgame ? "endgame" : "" }>
				<div className="bg-image--endgame"></div>
				<div className="bg-image"></div>
			</div>
			<div id="main-view" className={ this.state.endgame ? "endgame" : "" }>
				<audio id="audio" src={process.env.PUBLIC_URL + "/theme_short.mp3"}></audio>
				<div id="logo-container" className={ this.state.endgame ? "endgame" : "" }>
					<img className="micf" src={ process.env.PUBLIC_URL + "/micfLogo.png"} alt="Maybe I can Finish"/>
					<img className="mics" src={ process.env.PUBLIC_URL + "/micsLogo.png"} alt="Maybe I can Finish"/>
				</div>
				{ this.state.view === "selection" ? <RouletteView /> : <SpinningView length={ this.state.audiolength } data={ this.data }/> }
			</div>
			</>
		)
	}
}

export default MainView