import React, { Component } from 'react';

import './Spinner.scss';

import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';
import { teamPictures } from '../../../utils/config';

const FadeIn = styled.div`animation: 1s ${keyframes `${fadeIn}`}`;

export class Spinner extends Component {

	spinningInterval;
	
	// set the inital data. Provided there are more then 2 people in the array.
	state = {
		"data":[],
		"spinning": false,
		"stopSpinning": false,
		"spinningOffset": 0,
		"currentId": null,
		"nextId": null,
		"downToLastPerson": false,
		"pressedStopOnce": false,
		"thankYou": false,
		"audiolength": 0,
		"currentImage": 0
	}
	
	// get the data from the parent and set it to the current state's data
	constructor(data, length) {
		super()
		this.state.data = data.data;
		this.state.audiolength = data.length;
		this.startSpin = this.startSpin.bind(this);
		this.stopSpin = this.stopSpin.bind(this);
		this.updateState = this.updateState.bind(this);
		this.spinDatShit = this.spinDatShit.bind(this);
		this.startCreatingPictures = this.startCreatingPictures.bind(this);
		this.showThankYouMessage = this.showThankYouMessage.bind(this);
		this.randomInteger = this.randomInteger.bind(this);
	}
	
	updateState(){
		let currentId, nextId
		currentId = this.state.currentId < (this.state.data.length - 1) ? this.state.currentId + 1 : 0;
		nextId = currentId < (this.state.data.length - 1) ? currentId + 1 : 0;

		this.setState({ 
			"currentId": currentId,
			"nextId": nextId,
		});
	}
	
	startSpin(e) {
		if( e.keyCode === 32 ) {
			if( this.state.currentId !== null && this.state.nextId !== null ) {
				// remove the name from the data, that was selected from the spinning
				this.state.data.splice(this.state.nextId, 1);
				console.log('removing data');

				if( this.state.data.length > 1 ) {
					this.updateState();
				}
			} else {
				this.setState({
					"currentId": 0,
					"nextId": 1,
				});
				console.log("setting id", this.state.currentId, this.state.nextId);
			}

			if( this.state.data.length < 2 ) {
				console.log("last person");
				this.lastPerson();
			} else {
				// set the state to spinning
				this.setState({ "spinning": true });

				// create the interval to animate the spinner
				this.spinningInterval = setInterval(this.spinDatShit, 10);
				// remove the event listener to prevent from restarting the spin
				document.removeEventListener("keydown", this.startSpin, false);
				// add event listener to stop the spin
				document.addEventListener("keydown", this.stopSpin, false);
			}
		}
	}


	
	stopSpin(e) {
		if(e.keyCode === 32 ) {
			if( !this.state.pressedStopOnce ) {
				this.setState({pressedStopOnce: true})
				console.log("pressed stop")
			} else {
				console.log("do nothing")
				console.log(this.state);
			}
			// // set the stopSpinning state
			// this.setState({
			// 	"stopSpinning": true,
			// 	"spinning": false
			// });
			
			// // remove the event listener to stop the spin ...
			// document.removeEventListener("keydown", this.stopSpin, false);
			// // ... and add the event listener to start the spin again
			// document.addEventListener("keydown", this.startSpin, false);
			// console.log("callback: stopSpin(), ", this.state.spinning);
		}
	}
	
	spinDatShit() {
		// get all the lines, namely 2, from the spinner
		let lines = document.getElementsByClassName("name-line")
		
		// if the offset becomes 60, change the names before resetting it to 100
		if ( this.state.spinningOffset === 60 && this.state.stopSpinning === false) {
			this.updateState();
		}

		// set the offset to move the lines and simulate spinning, if it reaches 60 set it to 0, after the positions have been changed
		this.setState({"spinningOffset": this.state.spinningOffset === 60 ? 0 : this.state.spinningOffset + 4 });

		// the animation itself to simulate the spinning
		for (let i = 0; i < lines.length; i++) {
			lines[i].style.transform = `translateY(-${ this.state.spinningOffset }px)`
		}
		
		// if somewhere the stop has been triggered, wait for the animation to animate to 0 and then set stopSpinning to false
		if ( this.state.stopSpinning === true && this.state.spinningOffset === 60 ) {
			clearInterval(this.spinningInterval);

			this.setState({
				"stopSpinning": false,
			})
		}
	}
	
	lastPerson () {
		this.setState({
			"downToLastPerson": true
		});
	}

	randomInteger(min, max) {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	startCreatingPictures() {
		const imageSize = 1000;
		let i = 0;
		let mainView = document.getElementById("main-view");
		
		let containerDiv = document.createElement("div");

		containerDiv.classList.add("container-div");
		containerDiv.id = "container-div";

		mainView.appendChild(containerDiv)

		let interval = setInterval(() => {
			let div = document.createElement("div");
			let img = document.createElement("img");
			div.classList.add("teamImage","teamImage-"+teamPictures[i]);
			img.src = process.env.PUBLIC_URL+ "/team/" + teamPictures[i];
			img.onload = () => {
				if( img.naturalWidth > img.naturalHeight ) {
					// Bild breit
					div.style.width = imageSize + "px"
					div.style.height = "auto"
					img.style.width = "100%";
					img.style.height = "auto"
					let x = this.randomInteger(10, window.innerWidth - imageSize - 10) + "px";
					let y = this.randomInteger(10, window.innerHeight - (imageSize / img.naturalHeight) * img.naturalHeight - 10 ) + "px";
					div.style.top = y
					div.style.left = x
					console.log(x + " " + y + " Quer");
				} else {
					// Bild hochkant
					div.style.width = "auto"
					div.style.height = imageSize + "px"
					img.style.width = "auto"
					img.style.height = "100%"
					let x = this.randomInteger(10, window.innerWidth - (imageSize / img.naturalWidth) * img.naturalWidth - 10 ) + "px";
					let y = this.randomInteger(10, window.innerHeight - imageSize - 10) + "px";
					div.style.left = x;
					div.style.top = y;
					console.log(x + " " + y + " Hoch");
				}

				div.style.position = "absolute"
				div.style.borderColor = "#fff"
				div.style.borderWidth = "10px"
				div.style.borderStyle = "solid"
				// div.style.transform = "rotateZ(" + this.randomInteger(-3, 3) + "deg)"

				div.appendChild(img);
				containerDiv.appendChild(div);
				setTimeout(() => {
					div.classList.add("visible")
				}, 100)
				
			}

			i++;
			this.setState({currentImage: i})
			
			if(i >= teamPictures.length - 1) {
				clearInterval(interval);
				this.showThankYouMessage();
			}
		}, this.state.audiolength * 1000 / (teamPictures.length + 3) );

		setTimeout(() => {
			console.log("over");
			this.setState({thankYou : true})
		}, this.state.audiolength*1000)

	}
	
	componentDidMount(){
		this.setState({ "spinning": true });

		// create the interval to animate the spinner
		this.spinningInterval = setInterval(this.spinDatShit, 10);
		this.startCreatingPictures()

		// add event listener to stop the spin
		document.addEventListener("keydown", this.stopSpin, false);
	}

	showThankYouMessage() {
		let img = document.createElement("img");
		let div = document.createElement("div");
		div.classList.add("teamImage","teamImage-final");
		console.log("final: " + process.env.PUBLIC_URL+ "/team/" + teamPictures[teamPictures.length - 1]);
		img.src = process.env.PUBLIC_URL+ "/team/" + teamPictures[teamPictures.length - 1];
		
		img.onload = () => {
			div.appendChild(img);
			console.log(document.getElementById("container-div"));
			document.getElementById("container-div").appendChild(div);
			setTimeout(() => {
				div.classList.add("visible")
			}, 100)
		}
	}

	componentWillUnmount(){
	}

	render() {
		let spinnerView;
		if ( this.state.downToLastPerson && !this.state.thankYou ) {
			spinnerView = <FadeIn><div id="last-person"
							   className="last-person">
							   	<div className="first-line">Already Down to last Person...</div>
							   	<div id="last-spinner-image" style={{ backgroundImage: 'url(' + (this.state.nextId !== null ? process.env.PUBLIC_URL + '/' + this.state.data[0].name + '.png' : '') + '), url(' + process.env.PUBLIC_URL + '/Question.png)' }}>&nbsp;</div>
						  		<div id="last-spinner-line">{ this.state.data[0].name }</div>
						  </div></FadeIn>
		} else if ( !this.state.thankYou ) {
			spinnerView = <div>
		 		<div id="spinner-image" className={ this.state.nextId !== null && !this.state.spinning ? "visible" : "hidden" } style={{ backgroundImage: 'url(' + (this.state.nextId !== null ? process.env.PUBLIC_URL + '/' + this.state.data[this.state.nextId].name + '.png' : '') + '), url(' + process.env.PUBLIC_URL + '/Question.png)' }}></div>			
				<div id="spinner"
					 className="spinner">
						<div id="name-line-first" data-id={ this.state.data[Number.isInteger(this.state.currentId) ? this.state.currentId : 0].id } className="name-line current">{ this.state.data[Number.isInteger(this.state.currentId) ? this.state.currentId : 0].name }</div>
						<div data-id={ this.state.data[Number.isInteger(this.state.nextId) ? this.state.nextId : 1].id } className="name-line next">{ this.state.data[Number.isInteger(this.state.nextId) ? this.state.nextId : 1].name }</div>
				</div>
			</div>
		} else {
			spinnerView = <div></div>
		}
		return (
			<div>
				{ spinnerView }
				<div id="counter">
					{ this.state.currentImage } / { teamPictures.length }
				</div>
			</div>
		)
	}
}

export default Spinner;