import * as Icons from '@fortawesome/free-solid-svg-icons';

const roles = {
	dev: { name: "dev", icon: Icons.faTerminal },
	designer: { name: "designer", icon: Icons.faPalette }
}

const members = [
	{ "id": 1, "name": "Slawa", "role": roles.dev },
	{ "id": 2, "name": "Rok", "role": roles.dev },
	{ "id": 3, "name": "Ludovico", "role": roles.dev },
	{ "id": 4, "name": "Oliver", "role": roles.dev },
	{ "id": 5, "name": "Robert", "role": roles.dev },
	{ "id": 6, "name": "Tobi", "role": roles.dev },
	{ "id": 7, "name": "Benni", "role": roles.designer },
	{ "id": 8, "name": "Yelena", "role": roles.designer },
];

function shuffle(a) {
	for (let i = a.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[a[i], a[j]] = [a[j], a[i]];
	}
	return a;
}

const teamPictures = shuffle([
	"10502158386766636501.JPG",
	"11820148481654248614.JPG",
	"12643710138145795224.JPG",
	"14567049045829011581.JPG",
	"15560170131698367147.JPG",
	"17305872464613592724.JPG",
	"17360991062074079605.JPG",
	"20190206_141906_HDR.jpg",
	"20190402_190206.jpg",
	"20190917_101357_HDR.jpg",
	"20190917_123840_HDR.jpg",
	"20190917_141145.jpg",
	"20190918_091234_HDR.jpg",
	"20190918_094939_HDR.jpg",
	"20190918_095837_HDR.jpg",
	"20190918_124942.jpg",
	"20191203_161435.jpg",
	"20200121_214601.jpg",
	"20200217_123954.jpg",
	"20200217_160451.jpg",
	"20200217_213331.jpg",
	"20200219_110214.jpg",
	"20200219_112207.jpg",
	"20200219_115708_HDR.jpg",
	"20200219_124315_HDR.jpg",
	"2270523387815075976.JPG",
	"2858443595153125125.JPG",
	"3249415667142364761.JPG",
	"3272302417088450176.JPG",
	"3333008598450668483.JPG",
	"4008216375838319770.JPG",
	"4011659272205190291.JPG",
	"5068539271779625142.JPG",
	"5543367664368116439.JPG",
	"6526351991917087424.JPG",
	"6765432465723781311.JPG",
	"7412714046655239228.JPG",
	"7997227538203585702.JPG",
	"8301406668583800204.JPG",
	"8558233031259381087.JPG",
	"9004808402712597353.JPG",
	"IMG-20180630-WA0000.jpg",
	"IMG-20181218-WA0004.jpg",
	"IMG-20190403-WA0001.jpg",
	"IMG-20190506-WA0010.jpg",
	"IMG-20190604-WA0000.jpg",
	"IMG-20190629-WA0002.jpg",
	"IMG-20190720-WA0001.jpg",
	"IMG-20190917-WA0000.jpg",
	"IMG-20190918-WA0001.jpg",
	"IMG-20190918-WA0004.jpg",
	"IMG-20190923-WA0001.jpg",
	"IMG-20190923-WA0002.jpg",
	"IMG-20190923-WA0003.jpg",
	"IMG-20191007-WA0007.jpg",
	"IMG-20191008-WA0000.jpg",
	"IMG-20191008-WA0001.jpg",
	"IMG-20191015-WA0000.jpg",
	"IMG-20191022-WA0001.jpg",
	"IMG-20191022-WA0003.jpg",
	"IMG-20191022-WA0012.jpg",
	"IMG-20191105-WA0003.jpg",
	"IMG-20191105-WA0006.jpg",
	"IMG-20191105-WA0010.jpg",
	"IMG-20191105-WA0011.jpg",
	"IMG-20191105-WA0013.jpg",
	"IMG-20191119-WA0004.jpg",
	"IMG-20191119-WA0008.jpg",
	"IMG-20191119-WA0021.jpg",
	"IMG-20191119-WA0030.jpg",
	"IMG-20191119-WA0041.jpg",
	"IMG-20191128-WA0008.jpg",
	"IMG-20191204-WA0005.jpg",
	"IMG-20191217-WA0013.jpg",
	"IMG-20200121-WA0001.jpg",
	"IMG-20200121-WA0006.jpg",
	"IMG-20200121-WA0007.jpg",
	"IMG-20200122-WA0002.jpg",
	"IMG-20200122-WA0011.jpg",
	"IMG_20190402_190244.jpg",
	"IMG_20190402_190319.jpg",
	"IMG_20190729_190051.jpg",
	"IMG_20190729_201125_2.jpg",
	"IMG_20190729_205244.jpg",
	"IMG_20190729_221647.jpg",
	"IMG_20190826_150457.jpg",
	"IMG_20190827_170858.jpg",
	"IMG_20190827_170900.jpg",
	"IMG_20190827_171014.jpg",
	"IMG_20190827_171041.jpg",
	"IMG_20190827_191757.jpg",
	"IMG_20190917_100737.jpg",
	"IMG_20190917_123852.jpg",
	"IMG_20190917_131435.jpg",
	"IMG_20190917_171758.jpg",
	"IMG_20190917_215458.jpg",
	"IMG_20190918_090415.jpg",
	"IMG_20190918_090541.jpg",
	"IMG_20190918_090948.jpg",
	"IMG_20190918_093802.jpg",
	"IMG_20190918_094657.jpg",
	"IMG_20190918_100125.jpg",
	"IMG_20190918_100139.jpg",
	"IMG_20190918_134319.jpg",
	"IMG_20190918_144016.jpg",
	"IMG_20190918_204133.jpg",
	"IMG_20190918_205946.jpg",
	"IMG_20191105_195735.jpg",
	"IMG_20191203_182309.jpg",
	"IMG_20191203_182321.jpg",
	"IMG_20191217_131744.jpg",
	"IMG_20191217_205039.jpg",
	"IMG_20191217_205509.jpg",
	"IMG_20200121_132149.jpg",
	"IMG_20200121_185618.jpg",
	"IMG_20200204_193444.jpg",
	"IMG_20200204_193821.jpg",
	"IMG_20200218_170924.jpg"
]).concat(["thankyou2.png"]);

const settings = {
	animationScale: 1,
}

export default members;
export { settings, teamPictures }